import * as THREE from 'three';
import FurnitureMaterialLibrary from '../model/FurnitureMaterialLibrary';
;
class EmissionMaterial {
    // SE ANCHE UNO SOLO DEI SEGUENTI CAMPI XML NON E' PRESENTE L'EMISSIONE NON SI ATTIVA
    // indica se l'emissione è attiva (ha senso mandare l'emissione se non viene utilizzata?)
    enabled;
    // potenza dell'emissione
    power;
    // efficancy dell'emissione
    efficancy;
    // area light units del materiale
    // nel parser java è ENUM con un solo valore "watts", ma ho già trovato un materiale che ha come valore "watts_m2", perché?
    units;
    // colore dell'emissione
    // il valore di ogni canale è diviso da ":" (R,G,B,A)
    color;
    constructor(xml) {
        if (!xml)
            return;
    }
}
class FurnitureMaterial {
    // nella classe java questa stringa identifica il materiale all'interno di una libreria contenuta nel seguente file:
    // https://bitbucket.org/tecnobit/design-tool/src/56a6e3e4e7f1083c6fc2d601c94760685eba020b/src/main/java/stilert/render/material/MaterialLibrary.java
    materialType; //o ENUM?
    // credo identifichi il materiale creato per associarlo alla/e geometria/e corrispondente/i
    name;
    // identifica il tipo di materiale, è un ENUM ma non ho capito se è di qualche utilità
    // riga 722 del seguente file
    // https://bitbucket.org/tecnobit/design-tool/src/master/src/main/java/stilert/render/model/StileMaterial.java
    type;
    // indica se il materiale è visibile?
    // se non presente è true
    visible;
    // indica il colore del materiale
    // se non presente è bianco ma solo per i seguenti materialType: TL_Default, TL_base_glossy, TL_thin_glass, TL_leather, TL_water
    color;
    // indica la priorità del materiale? perché alcuni materialType non la hanno?
    materialLibraryOrder;
    // indica l'indice o il codice della texture associata al materiale
    // prima testare come indice e poi come codice, se è nullo e sono presenti delle texture prendere la prima disponibile
    texture; // o string?
    diffuseTexture;
    // todo: add other type of texture
    //public bumpTexture: THREE.Texture;
    // indica se usare la texture oppure no, i valori sono "color" o "texture"
    colorType; // o ENUM? o boolean?
    // x, y, larghezza, altezza del rettangolo della texture
    // forse serve per l'UV mapping
    // riga 200 del seguente file
    // https://bitbucket.org/tecnobit/design-tool/src/master/src/main/java/stilert/render/model/StileMaterial.java
    rectangle;
    flipHorizontal;
    flipVertical;
    // rotazione della texture
    // campo "rotation" dell'XML (se non presente "textureRotation" per retrocompatibilità)
    rotation;
    // colore della riflettenza del materiale
    // se è presente un unico valore che inizia con la "L" è da associare a ogni canale R,G e B
    // altrimenti il valore di ogni canale è diviso da ":" (R,G,B)
    // DEFAULT_REFLECTANCE = 0;
    reflectance;
    // percentuale di riflettenza del materiale (il campo XML si chiama "reflectance_perc")
    // il valore è da dividere per 100
    // DEFAULT_REFLECTANCE_PERCENTAGE = 0;
    reflectancePerc;
    // percentuale di roughness del materiale
    // il valore è da dividere per 100
    // DEFAULT_ROUGHNESS = 1;
    roughness;
    // bump height del materiale
    // DEFAULT_BUMP_COLOR = 0.10;
    bump;
    // percentuale di bump del materiale
    // il valore è da dividere per 100
    // DEFAULT_BUMP_PERCENTAGE = 1;
    bumpPerc;
    // scala della texture
    // campo "scale" dell'XML (se non presente "textureScale" per retrocompatibilità)
    scale;
    // traslazione della texture
    // campo "translation" dell'XML (se non presente "textureOffset" per retrocompatibilità)
    translation;
    // nel parser java prende sempre e solo il primo elemente nell'array di emissioni del materiale, perché?
    emission;
    // identifica il tipo di texture mapping
    // riga 796 del seguente file
    // https://bitbucket.org/tecnobit/design-tool/src/master/src/main/java/stilert/render/model/StileMaterial.java
    textureProjectionType; // o ENUM?
    // indice di rifrazione del materiale
    // DEFAULT_INDEX_OF_REFRACTION = 1;
    indexOfRefraction;
    // extinction coefficient del materiale
    extinctionCoeff;
    // per raggruppare i materiali
    // riga 775 del seguente file
    // https://bitbucket.org/tecnobit/design-tool/src/master/src/main/java/stilert/render/model/StileMaterial.java
    group; // o ENUM?
    appearsInReflections;
    // immagine di anteprima in base64, è di qualche utilità?
    libraryPreviewImage;
    toThreeMaterial() {
        const material = FurnitureMaterialLibrary.getMaterialByName(this.materialType);
        const isTransparent = !!this.group && this.group === 'Glass';
        const isMetal = !!this.group && this.group === 'Metal';
        const isGlossy = !!this.group && this.group === 'Glossy';
        const envMap = FurnitureMaterialLibrary.envMap;
        let color = !!material ? material.color : this.color;
        let roughness = 1;
        if (isMetal || isGlossy) {
            if (!!material)
                color = material.reflectanceColor;
            else if (!!this.reflectance)
                color = this.reflectance;
        }
        if (!!material)
            roughness -= material.reflectancePercentage;
        else if (this.reflectancePerc != undefined)
            roughness -= this.reflectancePerc;
        return new THREE.MeshStandardMaterial({
            color: color,
            transparent: isTransparent,
            opacity: isTransparent ? 0.6 : 1,
            map: this.diffuseTexture,
            // bumpMap:
            roughness: roughness,
            metalness: isMetal ? 1 : 0,
            // roughnessMap:
            envMap: envMap,
            envMapIntensity: isMetal ? 1 : 1.5,
            //wireframe: true,
            // depthTest: false
        });
    }
    constructor(materialData, texturesData, textures) {
        // console.log(texturesData);
        if (!materialData)
            return;
        const colorArray = materialData.color.split(':');
        this.color = new THREE.Color();
        if (materialData.texture >= 0) {
            texturesData.children.forEach(item => {
                const code = item.texture.code;
                texturesData[code] = {
                    "code": code,
                    "bump": item.texture.bump,
                    "diffuse": item.texture.diffuse
                };
            });
            const textureData = texturesData[materialData.texture];
            // console.log(textureData);
            this.diffuseTexture = textures.find(t => { return t.name === textureData.diffuse; })?.texture;
        }
        this.materialType = materialData.materialType;
        this.color.r = parseFloat(colorArray[0]) / 255;
        this.color.g = parseFloat(colorArray[1]) / 255;
        this.color.b = parseFloat(colorArray[2]) / 255;
        if (this.reflectance != undefined) {
            this.reflectance.r = parseFloat(colorArray[0]) / 255;
            this.reflectance.g = parseFloat(colorArray[1]) / 255;
            this.reflectance.b = parseFloat(colorArray[2]) / 255;
        }
        let reflectancePerc = materialData.reflectance_perc;
        if (reflectancePerc?.startsWith('I') || reflectancePerc?.startsWith('L'))
            this.reflectancePerc = +reflectancePerc.substring(reflectancePerc.indexOf(':') + 1) / 100;
        this.colorType = materialData.colorType;
        this.flipHorizontal = materialData.flipHorizontal === 'true';
        this.flipVertical = materialData.flipVertical === 'true';
        this.group = materialData.group;
    }
}
export { FurnitureMaterial, EmissionMaterial };

import * as THREE from 'three';
export default class FurnitureMaterialLibrary {
    static envMap;
    static async loadEnvMap() {
        if (this.envMap)
            return new Promise(resolve => resolve(this.envMap));
        return new Promise((resolve, reject) => {
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load('city.jpg', texture => resolve(texture), null, error => reject(error));
        }).then((texture) => {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.envMap = texture;
            return this.envMap;
        });
    }
    static TL_Default = {
        name: "TL_Default",
        materialLibraryId: "TL_Default",
        materialLibraryOrder: 0,
        reflectancePercentage: 0,
        color: new THREE.Color(0xFFFFFF),
        roughnessPercentage: 1,
        // refractionType: (StileMaterial.RefractionType.Basic),
        // group: (StileMaterial.MaterialGroup.Opaque)
    };
    static TL_black_opaque = {
        name: "TL_black_opaque",
        materialLibraryId: "TL_black_opaque",
        color: new THREE.Color(0x000000),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 0,
        // refractionType: (StileMaterial.RefractionType.Basic),
        // group: (StileMaterial.MaterialGroup.Opaque)
    };
    static TL_blue_opaque = {
        name: "TL_blue_opaque",
        materialLibraryId: "TL_blue_opaque",
        color: new THREE.Color(0x000000),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_cyan_opaque = {
        name: "TL_cyan_opaque",
        materialLibraryId: "TL_cyan_opaque",
        color: new THREE.Color(0x00FFFF),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_green_opaque = {
        name: "TL_green_opaque",
        materialLibraryId: "TL_green_opaque",
        color: new THREE.Color(0x00FF00),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_grey_medium_opaque = {
        name: "TL_grey_medium_opaque",
        materialLibraryId: "TL_grey_medium_opaque",
        color: new THREE.Color(0x808080),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_grey_light_opaque = {
        name: "TL_grey_light_opaque",
        materialLibraryId: "TL_grey_light_opaque",
        color: new THREE.Color(0xC0C0C0),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_magenta_opaque = {
        name: "TL_magenta_opaque",
        materialLibraryId: "TL_magenta_opaque",
        color: new THREE.Color(0xFF00FF),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_red_opaque = {
        name: "TL_red_opaque",
        materialLibraryId: "TL_red_opaque",
        color: new THREE.Color(0xFF0000),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_yellow_opaque = {
        name: "TL_yellow_opaque",
        materialLibraryId: "TL_yellow_opaque",
        color: new THREE.Color(0xFF00FF),
        reflectanceColor: new THREE.Color(0x000000),
        indexOfRefraction: new THREE.Vector3(1, 1, 1),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Opaque)
    };
    static TL_base_glossy = {
        name: "TL_base_glossy",
        materialLibraryId: "TL_base_glossy",
        materialLibraryOrder: 1,
        reflectancePercentage: 0.9,
        reflectanceColor: new THREE.Color(0.501961, 0.501961, 0.501961),
        indexOfRefraction: new THREE.Vector3(5, 5, 5),
        roughnessPercentage: 0.05,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_black_glossy = {
        name: "TL_black_glossy",
        materialLibraryId: "TL_black_glossy",
        color: new THREE.Color(0x000000),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_blue_glossy = {
        name: "TL_blue_glossy",
        materialLibraryId: "TL_blue_glossy",
        color: new THREE.Color(0x000000),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_cyan_glossy = {
        name: "TL_cyan_glossy",
        materialLibraryId: "TL_cyan_glossy",
        color: new THREE.Color(0x00FFFF),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_green_glossy = {
        name: "TL_green_glossy",
        materialLibraryId: "TL_green_glossy",
        color: new THREE.Color(0x00FF00),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_grey_medium_glossy = {
        name: "TL_grey_medium_glossy",
        materialLibraryId: "TL_grey_medium_glossy",
        color: new THREE.Color(0x808080),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_grey_light_glossy = {
        name: "TL_grey_light_glossy",
        materialLibraryId: "TL_grey_light_glossy",
        color: new THREE.Color(0xC0C0C0),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_magenta_glossy = {
        name: "TL_magenta_glossy",
        materialLibraryId: "TL_magenta_glossy",
        color: new THREE.Color(0xFF00FF),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_red_glossy = {
        name: "TL_red_glossy",
        materialLibraryId: "TL_red_glossy",
        color: new THREE.Color(0xFF0000),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_yellow_glossy = {
        name: "TL_yellow_glossy",
        materialLibraryId: "TL_yellow_glossy",
        color: new THREE.Color(0xFF00FF),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.8, 1.8, 1.8),
        roughnessPercentage: 0.02,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Glossy)
    };
    static TL_copper = {
        name: "TL_copper",
        materialLibraryId: "TL_copper",
        materialLibraryOrder: 6,
        color: new THREE.Color(0xDB8F6B),
        reflectanceColor: new THREE.Color(0xDB8F6B),
        reflectancePercentage: 0.95,
        indexOfRefraction: new THREE.Vector3(0.306, 0.306, 0.306),
        extinctionCoefficient: new THREE.Vector3(0.288, 0.288, 0.288),
        roughnessPercentage: 0.05,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_copper_satin = {
        name: "TL_copper_satin",
        materialLibraryId: "TL_copper_satin",
        color: new THREE.Color(0xDB8F6B),
        reflectanceColor: new THREE.Color(0xDB8F6B),
        reflectancePercentage: 0.8,
        indexOfRefraction: new THREE.Vector3(1.02, 1.02, 1.02),
        extinctionCoefficient: new THREE.Vector3(6.85, 6.85, 6.85),
        roughnessPercentage: 0.1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_copper_satin_H = {
        name: "TL_copper_satin_H",
        materialLibraryId: "TL_copper_satin_H",
        color: new THREE.Color(0xDB8F6B),
        reflectanceColor: new THREE.Color(0xDB8F6B),
        reflectancePercentage: 0.8,
        indexOfRefraction: new THREE.Vector3(1.02, 1.02, 1.02),
        extinctionCoefficient: new THREE.Vector3(6.85, 6.85, 6.85),
        roughnessPercentage: 0.2,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_gold = {
        name: "TL_gold",
        materialLibraryId: "TL_gold",
        materialLibraryOrder: 7,
        color: new THREE.Color(0xF9D889),
        reflectanceColor: new THREE.Color(0xF9D889),
        reflectancePercentage: 0.95,
        indexOfRefraction: new THREE.Vector3(0.306, 0.306, 0.306),
        extinctionCoefficient: new THREE.Vector3(0.288, 0.288, 0.288),
        roughnessPercentage: 0.05,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_gold_satin = {
        name: "TL_gold_satin",
        materialLibraryId: "TL_gold_satin",
        color: new THREE.Color(0xF9D889),
        reflectancePercentage: 0.8,
        reflectanceColor: new THREE.Color(0xF9D889),
        indexOfRefraction: new THREE.Vector3(1.02, 1.02, 1.02),
        extinctionCoefficient: new THREE.Vector3(6.85, 6.85, 6.85),
        roughnessPercentage: 0.1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_gold_satin_H = {
        name: "TL_gold_satin_H",
        materialLibraryId: "TL_gold_satin_H",
        color: new THREE.Color(0xF9D889),
        reflectanceColor: new THREE.Color(0xF9D889),
        reflectancePercentage: 0.75,
        indexOfRefraction: new THREE.Vector3(1.02, 1.02, 1.02),
        extinctionCoefficient: new THREE.Vector3(6.85, 6.85, 6.85),
        roughnessPercentage: 0.2,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_silver = {
        name: "TL_silver",
        materialLibraryId: "TL_silver",
        color: new THREE.Color(0.2, 0.2, 0.2),
        reflectanceColor: new THREE.Color(0.95, 0.95, 0.95),
        reflectancePercentage: 1,
        indexOfRefraction: new THREE.Vector3(20, 20, 20),
        extinctionCoefficient: new THREE.Vector3(3.4727, 3.4727, 3.4737),
        roughnessPercentage: 0.05,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_silver_satin = {
        name: "TL_silver_satin",
        materialLibraryId: "TL_silver_satin",
        color: new THREE.Color(0.2, 0.2, 0.2),
        reflectanceColor: new THREE.Color(0.5, 0.5, 0.5),
        reflectancePercentage: 0.8,
        indexOfRefraction: new THREE.Vector3(9.35, 9.35, 9.35),
        extinctionCoefficient: new THREE.Vector3(6.85, 6.85, 6.85),
        roughnessPercentage: 0.35,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_chrome_metal = {
        name: "TL_chrome_metal",
        materialLibraryId: "TL_chrome_metal",
        materialLibraryOrder: 2,
        color: new THREE.Color(0.09, 0.09, 0.09),
        reflectanceColor: new THREE.Color(0.95, 0.95, 0.95),
        reflectancePercentage: 1,
        indexOfRefraction: new THREE.Vector3(20, 20, 20),
        extinctionCoefficient: new THREE.Vector3(0, 0, 0),
        roughnessPercentage: 0.05,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_satin_metal = {
        name: "TL_satin_metal",
        materialLibraryId: "TL_satin_metal",
        materialLibraryOrder: 4,
        color: new THREE.Color(0.205882, 0.205882, 0.205882),
        reflectanceColor: new THREE.Color(0.5, 0.5, 0.5),
        reflectancePercentage: 0.8,
        indexOfRefraction: new THREE.Vector3(9.35, 9.35, 9.35),
        extinctionCoefficient: new THREE.Vector3(6.85, 6.85, 6.85),
        roughnessPercentage: 0.35,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Metal)
    };
    static TL_thin_glass = {
        name: "TL_thin_glass",
        materialLibraryOrder: 9,
        materialLibraryId: "TL_thin_glass",
        color: new THREE.Color(1, 1, 1),
        reflectanceColor: new THREE.Color(1, 1, 1),
        reflectancePercentage: 0.999,
        indexOfRefraction: new THREE.Vector3(1.3, 1.3, 1.3),
        extinctionCoefficient: new THREE.Vector3(0, 0, 0),
        roughnessPercentage: 0,
        // .refractionType(StileMaterial.RefractionType.ThinFilm)
        // .group(StileMaterial.MaterialGroup.Glass)
    };
    static TL_thin_glass_green = {
        name: "TL_thin_glass_green",
        materialLibraryId: "TL_thin_glass_green",
        color: new THREE.Color(0xF6FFF5),
        indexOfRefraction: new THREE.Vector3(1.3, 1.3, 1.3),
        roughnessPercentage: 0,
        // extinctionCoefficient: new THREE.Vector3((float)Math.log(255.0 / 0xf6), 0, (float)Math.log(255.0 / 0xf5)),
        // .refractionType(StileMaterial.RefractionType.ThinFilm)
        // .group(StileMaterial.MaterialGroup.Glass)
    };
    static TL_thin_glass_light_blue = {
        name: "TL_thin_glass_light_blue",
        materialLibraryId: "TL_thin_glass_light_blue",
        indexOfRefraction: new THREE.Vector3(1.3, 1.3, 1.3),
        // extinctionCoefficient: new THREE.Vector3((float)Math.log(255.0 / 0xf4), (float)Math.log(255.0 / 0xf5), 0),
        // .refractionType(StileMaterial.RefractionType.ThinFilm)
        // .group(StileMaterial.MaterialGroup.Glass)
    };
    static TL_mirror = {
        name: "TL_mirror",
        materialLibraryId: "TL_mirror",
        materialLibraryOrder: 8,
        color: new THREE.Color(0.05, 0.05, 0.05),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        reflectancePercentage: 1,
        indexOfRefraction: new THREE.Vector3(50, 50, 50),
        extinctionCoefficient: new THREE.Vector3(0, 0, 0),
        roughnessPercentage: 1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Special)
    };
    static TL_water = {
        name: "TL_water",
        materialLibraryId: "TL_water",
        color: new THREE.Color(0.89, 0.85, 1),
        reflectanceColor: new THREE.Color(0.98, 0.98, 0.98),
        reflectancePercentage: 0.999,
        indexOfRefraction: new THREE.Vector3(1.33, 1.33, 1.33),
        extinctionCoefficient: new THREE.Vector3(1e-6, 1e-6, 1e-7),
        roughnessPercentage: 0,
        // .refractionType(StileMaterial.RefractionType.ThinFilm)
        // .group(MaterialGroup.Special)
    };
    static TL_porcelain = {
        name: "TL_porcelain",
        materialLibraryId: "TL_porcelain",
        materialLibraryOrder: 13,
        color: new THREE.Color(0.92549, 0.92549, 0.92549),
        reflectanceColor: new THREE.Color(0.8, 0.8, 0.8),
        reflectancePercentage: 0.9,
        indexOfRefraction: new THREE.Vector3(3, 3, 3),
        extinctionCoefficient: new THREE.Vector3(7.5, 7.5, 7.5),
        roughnessPercentage: 0.1,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Special)
    };
    static TL_leather = {
        name: "TL_leather",
        materialLibraryId: "TL_leather",
        color: new THREE.Color(0.05, 0.05, 0.05),
        // .texture(TextureImage.createFromResource(
        //         Optional.of("/stilert/render/materials/TL_leather_diffuse.jpg"),
        //         Optional.empty(),
        //         Optional.of("/stilert/render/materials/TL_leather_reflectance.jpg"))
        // )
        // .textureEnabled(true)
        reflectancePercentage: 1,
        reflectanceColor: new THREE.Color(0.462745, 0.462745, 0.462745),
        indexOfRefraction: new THREE.Vector3(7, 7, 7),
        extinctionCoefficient: new THREE.Vector3(1.0, 1.0, 1.0),
        roughnessPercentage: 0.67,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Leather)
    };
    static TL_leather_03 = {
        name: "TL_leather_03",
        materialLibraryId: "TL_leather_03",
        color: new THREE.Color(0.05, 0.05, 0.05),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.5, 1.5, 1.5),
        extinctionCoefficient: new THREE.Vector3(0.7, 0.7, 0.7),
        roughnessPercentage: 0.28,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Leather)
    };
    static TL_leather_12 = {
        name: "TL_leather_12",
        materialLibraryId: "TL_leather_12",
        color: new THREE.Color(0.05, 0.05, 0.05),
        reflectanceColor: new THREE.Color(0xFFFFFF),
        indexOfRefraction: new THREE.Vector3(1.5, 1.5, 1.5),
        extinctionCoefficient: new THREE.Vector3(1.0, 1.0, 1.0),
        roughnessPercentage: 0.05,
        bumpPercentage: 0.2,
        // .refractionType(StileMaterial.RefractionType.Basic)
        // .group(StileMaterial.MaterialGroup.Leather)
    };
    static getMaterialByName(name) {
        return this[name];
    }
}
